@font-face {
  font-family: 'SVN-Gilroy';
  /*a name to be used later*/
  src: url('../assets/fonts/SVN-Gilroy\ Regular.otf');
  /*URL to font*/
}

@import 'https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap';

@mixin scroll-vertical {
  &::-webkit-scrollbar {
    -webkit-appearance: none !important;
    position: absolute !important;
  }

  &::-webkit-scrollbar:vertical {
    width: 8px !important;
  }

  &::-webkit-scrollbar-thumb:vertical {
    background-color: rgba(0, 0, 0, 0.5) !important;
    border: none !important;
    border-radius: 8px !important;
  }
}

@mixin scroll-horizontal {
  &::-webkit-scrollbar {
    -webkit-appearance: none !important;
    // position: absolute !important;
  }

  &::-webkit-scrollbar:horizontal {
    height: 8px !important;
  }

  &::-webkit-scrollbar-thumb:horizontal {
    background-color: rgba(0, 0, 0, 0.5) !important;
    border: none !important;
    border-radius: 8px !important;
  }
}

html {
  --colorLighter: #ccc;
  --colorLight: #ccc;
  --colorNormal: #ccc;
  --colorBold: #ccc;
  --colorBolder: #ccc;

  --colorDanger: #ff4d4f;
  --colorWarning: #faad14;
  --colorSuccess: #52c41a;
  --colorSecondary: #000000e0;

  --colorBgDanger: #fff2f0;
  --colorBgWarning: #fffbe6;
  --colorBgSuccess: #f6ffed;
  --colorBgSecondary: #fafafa;
}

.bg-danger {
  background-color: var(--colorBgDanger) !important;
  color: var(--colorDanger) !important;
}

.bg-warning {
  background-color: var(--colorBgWarning) !important;
  color: var(--colorWarning) !important;
}

.bg-success {
  background-color: var(--colorBgSuccess) !important;
  color: var(--colorSuccess) !important;
}

.bg-secondary {
  background-color: var(--colorBgSecondary) !important;
  color: var(--colorSecondary) !important;
}

body {
  background-color: #f3f3f3;
  margin: 0;
  font-family: 'Lexend', 'SVN-Gilroy';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  @include scroll-horizontal;
  @include scroll-vertical;
}

a {
  color: var(--colorNormal);

  &:hover {
    color: var(--colorBold);
  }
}

.auth_layout {
  // background: url(../src/res/images/background.svg) no-repeat center center
  //     fixed;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.waviy {
  position: relative;
  -webkit-box-reflect: below -1.25rem linear-gradient(transparent, rgba(0, 0, 0, 0.2));
}

.waviy span {
  position: relative;
  display: inline-block;
  font-size: 2.5rem;
  color: #000;
  text-transform: uppercase;
  animation: waviy 1s infinite;
  animation-delay: calc(0.1s * var(--i));
}

@keyframes waviy {
  0%,
  40%,
  100% {
    transform: translateY(0);
  }

  20% {
    transform: translateY(-1.25rem);
  }
}

#preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  background-color: #fff;
}

[data-theme-version='dark'] {
  #preloader {
    background: #171622;
  }

  .waviy span {
    color: #fff;
  }
}

.scroll-horizontal {
  @include scroll-horizontal;
}

.scroll-vertical {
  @include scroll-vertical;
}

.hidden {
  display: none !important;
}

.checkbox-list {
  label {
    min-width: 100px !important;
  }
}

.checkbox {
  background-color: var(--colorNormal);
}

.tag-parent {
  background-color: #fff;
}

.ant-tag-checkable-checked.tag-parent {
  background-color: #bae4f0;
}

.row-parent {
  background-color: #ebeced !important;

  &:hover {
    background-color: #ebeced !important;
  }

  .ant-table-cell {
    background-color: #ebeced !important;
  }
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.text-wrap {
  text-wrap: wrap !important;
}

// .ant-menu-submenu-selected {
//   .ant-menu-submenu-title {
//     background-color: #bae4f0;
//   }
// }

.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 1;
}

.dragHandler:hover {
  cursor: move;
  background-color: #ccc;
}

.ant-table-wrapper.table-custom .ant-table-container table > thead > tr:first-child > *:first-child {
  border-bottom-left-radius: 16px;
}

.ant-table-wrapper.table-custom .ant-table-container table > thead > tr:first-child > *:last-child {
  border-bottom-right-radius: 16px;
}

.ant-layout-sider,
.ant-layout-sider-trigger {
  border-inline-end: 1px solid rgba(5, 5, 5, 0.06);
}

.ant-menu-light.ant-menu-root.ant-menu-inline,
.ant-menu-light.ant-menu-root.ant-menu-vertical {
  border-inline-end: unset;
}

.table-custom .ant-table-cell {
  border-bottom: unset !important;
}

.ant-table.ant-table-bordered th {
  border-color: #dcdcdc !important;
}

.input-search .ant-input-affix-wrapper {
  border-radius: unset;
  // border: unset;
  outline: unset;
  box-shadow: unset;
}

.ant-scroll-number.ant-badge-count {
  z-index: 9;
}

.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 26px;
  line-height: 24px;
  font-size: 14px;
  border-radius: 8px !important;
  margin-inline-start: 3px;
}

.property-category {
  padding: 6px 10px;
  border-radius: 10px;
  margin-bottom: 8px;

  .property-category-btn-wrapper {
    opacity: 0;
    transition: all 0.3s;

    .property-category-btn {
      cursor: pointer;
      padding: 6px;
      border-radius: 50%;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }

  &:hover .property-category-btn-wrapper {
    opacity: 1;
  }
}

.property {
  padding: 6px 10px;
  border-radius: 10px;
  margin-bottom: 8px;

  .property-btn-wrapper {
    opacity: 0;
    transition: all 0.3s;
  }

  .property-btn {
    cursor: pointer;
    padding: 6px;
    border-radius: 50%;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  &:hover .property-btn-wrapper {
    opacity: 1;
  }
}

.ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline {
  background-color: unset;
}

.ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding: 0;
}

.ant-tabs-left .ant-tabs-tab-active {
  background-color: var(--colorLight) !important;
}

.tab {
  background-color: #f1fcff;
  padding: 8px 18px;
  border-radius: 10px;
  color: #ccc;

  &.active {
    background-color: #7ce3ff;
    color: black;
  }
}

.tab + .tab {
  margin-top: 6px;
}

.custom-calendar {
  table {
    border: none !important;
  }

  tr {
    &:has(.fc-timegrid-slot-minor) {
      border-bottom: 2px solid var(--colorLight);
    }
  }

  th {
    a {
      color: black !important;
    }
  }

  td {
    a {
      color: black !important;
    }
  }

  tr {
    td.fc-day {
      &:nth-child(7) {
        padding-right: 0 !important;
      }

      &:nth-child(1) {
        padding-left: 0 !important;
      }
    }
  }

  tr[role='row'] {
    &:nth-child(1) {
      td {
        padding-top: 8px !important;
      }
    }
  }

  th,
  td {
    border: none !important;
  }

  th > div {
    border-radius: 12px;
    padding: 4px;
    background-color: var(--colorLight);
  }

  .fc-day-other {
    opacity: 0;
  }

  td.fc-day {
    padding: 4px;
    background-color: unset !important;
    height: 90px;

    .fc-scrollgrid-sync-inner {
      background-color: var(--colorLighter);
      border-radius: 14px;

      &:has(.event) {
        background-color: var(--colorLight);

        .fc-daygrid-day-number {
          color: white !important;
        }
      }
    }

    .fc-daygrid-day-top {
      position: absolute;
      height: 100%;
      width: 100%;
      justify-content: center;

      .fc-daygrid-day-number {
        font-size: 66px;
        line-height: 100%;
        font-weight: 900;
        color: #ebeced !important;
        cursor: default;
      }
    }

    .fc-daygrid-day-events {
      padding: 6px 4px;
    }

    .event {
      .fc-daygrid-event-dot {
        // display: none;
        border-color: var(--colorNormal);
      }

      &.fc-v-event {
        background-color: var(--colorLight);
        border: unset;

        .fc-event-main {
          color: black !important;
          padding: 6px;
        }
      }

      &.fc-timegrid-event {
        margin-bottom: 4px;
        border-radius: 8px;
        box-shadow: 0 0 4px 0 #a7a7a7;
      }

      &.disable {
        background-color: var(--colorNormal);
        color: white !important;

        .fc-daygrid-event-dot {
          border-color: white;
        }
      }
    }
  }
}

.fc-popover {
  border-radius: 14px;
  overflow: hidden;

  .fc-popover-header {
    background-color: var(--colorLight);
  }

  .fc-popover-body {
    padding-top: 4px !important;

    .fc-daygrid-event-dot {
      border-color: var(--colorNormal);
    }

    a {
      color: black !important;
    }
  }
}

.hover-item {
  &:hover {
    background-color: var(--colorLight);
  }
}

.list-item {
  padding: 4px 8px;
  border-radius: 10px;
  cursor: pointer;
  background-color: var(--colorLighter);
  margin-bottom: 4px;
  color: black;
}

.schedule-tag {
  font-size: 14px;
  background-color: var(--colorLighter);
  padding: 6px 12px;
  margin-bottom: 8px;

  &.ant-tag-checkable-checked {
    color: black;
    background-color: var(--colorLight);
  }

  &.active {
    color: white;
    background-color: var(--colorNormal);
  }
}

.slider-tooltip {
  .ant-tooltip-arrow {
    display: none;
  }

  .ant-tooltip-inner {
    color: black;
    background-color: unset;
    box-shadow: unset;
    padding: 0;
    margin-top: -10px;
  }
}

.schedule-shift {
  background-color: var(--colorLight);
  padding: 8px 12px;
  margin-bottom: 6px;
  border-radius: 12px;
  cursor: pointer;

  &.active {
    background-color: var(--colorNormal);
    color: white;
  }

  &.hidden-shift {
    opacity: 0;
    cursor: default;
  }
}

.schedule-shift-week-col {
  flex: 1;
  text-align: center;
  background-color: var(--colorLight);
  padding: 8px 0;

  &:first-child {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  &:last-child {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}

.tree-itinerary {
  .ant-tree-treenode {
    width: 100%;
    background-color: var(--colorLight);
    border-radius: 6px;
    padding: 4px 0;
    margin-bottom: 4px;

    .ant-tree-node-content-wrapper {
      flex: 1;

      &:hover {
        background-color: unset;
      }
    }
  }
}

.checkbox-user {
  .ant-checkbox-inner {
    transform: scale(1.5);
  }
}

.popover-user {
  .ant-popover-inner {
    width: 400px;
  }
}

.image-container {
  display: inline-block;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
}

.styled-image {
  display: block;
  width: 100%;
  height: auto;
}

.image-container {
  position: relative;
  display: inline-block;
  width: 300px;
  height: auto;
}

.image-1 {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  border-radius: 60px;
  width: 100%;
  height: auto;
  position: relative;
  z-index: 1;
}

.image-2 {
  border-radius: 50px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  width: 100%;
  height: auto;
  position: absolute;
  top: 85px;
  left: 25px;
  z-index: 2;
  width: 90%;
  height: auto;
  opacity: 0.9;
}

.editor-container .ck-editor__editable_inline {
  min-height: 300px;
  // min-width: 500px;
  background-color: var(--colorLight);
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  color: #333;
}

.editor-container .ck-toolbar {
  background-color: #fafafa;
  border-bottom: 1px solid #ddd;
}

.header-task {
  padding: 12px 24px 12px 36px;
  background-color: var(--colorLight);
  margin-bottom: 12px;
  border-radius: 16px;
}

.task-item {
  background-color: var(--colorLighter);
  margin-bottom: 6px;
  border: unset !important;
  border-radius: 16px !important;
  position: relative;
}

.task-collapse {
  background: unset;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  align-items: center;
}

.styled-container {
  display: flex;
  align-items: center;
  background-color: var(--colorLight);
  border: 1px solid var(--colorLight);
  border-radius: 10px;
  padding: 8px 16px;
}

.styled-container-payment {
  display: flex;
  align-items: center;
  background-color: var(--colorLight);
  border: 1px solid var(--colorLight);
  border-radius: 10px;
  width: 350px;
  padding: 8px 16px;
}

.styled-text {
  color: #a3a3a3;
}

.ant-card.card-border {
  border: 1px solid var(--colorNormal);
}

.course-card-container {
  border: #ccc solid 1px;
  border-radius: 20px;
  width: 300px;
}

.course-card {
  border: none;
  // border-radius: 10px;
  margin-bottom: 10px;
}

.name-info {
  margin-top: 10px;
  font-size: 1.2rem;
  line-height: 1.25rem;
}

.close-button-product {
  position: absolute;
  top: 12px;
  right: 70px;
  width: 30px;
  height: 30px;
  background-color: white;
  border: 2px solid red;
  border-radius: 50%;
  color: red;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  line-height: 24px;
  cursor: pointer;
  z-index: 1;
}

/* Adjusted the width of both elements to match */
.time-picker-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 20px;
  background-color: #f4f6f9;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  max-width: 400px;
  /* Set max-width to match both elements */
  margin: 0 auto;
}

/* Style for the BaseTimePicker */
.styled-time-picker {
  width: 100%;
  /* Full width of the container */
  padding: 14px;
  border: 2px solid #ff85c0;
  /* Pink border */
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;
  font-family: 'Lexend', sans-serif;
  color: #333;
  font-size: 16px;
}

/* Ensures the input inside the BaseTimePicker has full width */
.styled-time-picker input {
  width: 100%;
  /* Ensure input takes full width */
  box-sizing: border-box;
  /* Include padding and border in the element's total width */
}

/* Change border and shadow on focus */
.styled-time-picker:focus-within {
  border-color: #ff69b4;
  /* Brighter pink on focus */
  box-shadow: 0 8px 16px rgba(255, 105, 180, 0.3);
}

/* Style for the milliseconds container */
.milliseconds-container {
  width: 100%;
  /* Full width of the container */
  padding: 16px;
  border-radius: 10px;
  background-color: #ff85c0;
  /* Pink background */
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

/* Hover effect for the milliseconds container */
.milliseconds-container:hover {
  transform: scale(1.05);
}

/* Add media queries for better responsiveness */
@media (max-width: 500px) {
  .time-picker-container {
    padding: 16px;
    max-width: 100%;
    /* Adjust to fit smaller screens */
  }

  .styled-time-picker,
  .milliseconds-container {
    padding: 12px;
  }
}

.course-card .ant-card-cover img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.price-info {
  display: flex;
  justify-content: start;
  gap: 8px;
  align-items: center;
  margin-top: 10px;
}

.price-info .sale-info {
  background: #d6e70e;
  padding: 2px 8px;
  color: black;
  font-size: 18px;
  font-weight: bold;
  border-radius: 8px;
}

.price-info .voucher-info {
  background: #fa0632;
  color: white;
  padding: 2px 8px;
  font-size: 18px;
  border-radius: 8px;
  font-weight: bold;
}

.course-info {
  text-align: start;
}

.course-info h3 {
  margin: 10px 0;
  font-size: 20px;
  color: #333;
}

.original-price {
  color: #999;
  text-decoration: line-through;
}

.cover-image {
  object-fit: cover;
  object-position: center;
}

.discounted-price {
  color: #ff4d4f;
  font-size: 18px;
  font-weight: bold;
}

.default-button {
  display: inline-block;
  padding: 7px 16px;
  margin: 5px;
  background-color: white;
  color: inherit;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.selected-button {
  background-color: var(--colorNormal);
  color: white;
  border-color: var(--colorNormal);
}

.payment-details {
  margin-top: 20px;
  /* Adjust as needed */
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
}

.detail-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.detail-row label {
  font-weight: bold;
}

.detail-row span {
  flex: 1;
  text-align: right;
}

.detail-row:last-child {
  margin-bottom: 0;
}

.color-code {
  color: var(--colorNormal);
  cursor: pointer;
}

.session-step {
  .ant-steps-item-description {
    font-size: 12px;
    max-width: unset !important;
  }
}

.add-product-button {
  margin: 30px;
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  border: none;
  cursor: pointer;
}

.button-container {
  display: flex;
  justify-content: flex-end;
}

.no-padding {
  padding: 1px !important;
}

.border-radius-15 {
  border-radius: 15px !important;
}

.card-padding-sm {
  .ant-card-body {
    padding: 8px 14px;
  }
}

.autocomplete-large {
  input {
    font-size: 16px !important;
  }
}

.image-gallery {
  position: relative;
  margin: 25px 10px 10px 10px;
}

.more-images {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 1.5rem;
}

.image-cover {
  width: 90%;
  border-radius: 15px;
  height: 90%;
  object-fit: contain;
}

.ant-table-summary {
  .ant-table-cell {
    font-weight: 600;
  }
}

.setting-tree {
  .ant-tree-checkbox {
    align-self: center !important;
    margin-top: 0 !important;
  }
}

.avatar-uploader {
  .ant-upload.ant-upload-select {
    width: 140px !important;
    height: 140px !important;
    overflow: hidden;
  }
}

.custom-popover-content {
  width: 400px;
  // padding-top: 10px;
}

.list-chat {
  height: 450px;
  overflow: auto;
}

.search-container {
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: var(--colorLight);
  padding: 5px 10px;
  border: none;
  border-radius: 20px;
  outline: none;
  margin-bottom: 10px;
  height: 45px;
}

.search-input {
  flex: 1;
  border: none;
  outline: none;
  background: transparent;
  color: rgb(0, 0, 0);
  font-size: 16px;
  padding-left: 10px;
}

.search-input::placeholder {
  color: #ccc;
}

.chat-list {
  background-color: #3e6a96;
  color: #b0b3b8;
  width: 300px;
  padding: 10px;
}

.list-user {
  height: 500px;
  overflow-x: scroll;
}

.chat-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 12px;
  user-select: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.chat-item:hover {
  background-color: var(--colorLight);
}

.profile-pic {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}

.profile-pic img {
  width: 100%;
  height: 100%;
}

.chat-details {
  flex: 1;
}

.chat-name {
  font-weight: bold;
  color: #020;
  font-size: 16px;
}

.chat-message-converstation {
  font-size: 14px;
  color: #b0b3b8;
}

.chat-time {
  font-size: 12px;
  color: #b0b3b8;
}

:where(.css-dev-only-do-not-override-1psud85).ant-popover .ant-popover-inner {
  background-color: #ffffff;
  background-clip: padding-box;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  padding: 12px;
  margin-right: -65px;
  margin-top: 10px;
  overflow-y: scroll;
  width: 400px;
}

.floating-button-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.floating-button {
  background-color: #4267b2;
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  font-size: 18px;
  margin-bottom: 20px;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.avatar-in-button {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.chat-window {
  position: fixed;
  bottom: 20px;
  margin-right: 100px;
  width: 333px;
  height: 444px;
  // overflow-y: auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  z-index: 9999;
  background-color: white;
  opacity: 1;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.chat-header {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--colorNormal);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  align-items: center;
  background-color: var(--colorNormal);
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #888;
}

.close-button:hover {
  color: #555;
}

.chat-body {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.chat-message {
  border-radius: 5px;
  margin-bottom: 5px;
}

.message-content {
  margin: 5px 0;
  display: flex;
  flex-direction: column;

  &.received {
    align-items: flex-start;

    .message {
      background-color: #f1f1f1;
      color: black;
      border-radius: 15px 15px 15px 0;
    }
  }

  &.sent {
    align-items: flex-end;

    .message {
      background-color: var(--colorNormal);
      color: white;
      border-radius: 15px 15px 0 15px;
    }
  }

  .message {
    display: inline-block;
    word-wrap: break-word;
    max-width: 80%;
    padding: 8px 14px;
  }
}

.status {
  display: flex;
  align-content: flex-end;
  margin-top: 3px;
  font-size: 0.8em;
  color: gray;
}

.list-item-colapsse {
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 4px 8px;
  border-radius: 10px;
  cursor: pointer;
  background-color: var(--colorLighter);
  color: black;
}

.list-item-colapsse:hover {
  background-color: var(--colorNormal);
  /* Màu nền khi hover */
}

.custom-input {
  border-radius: 8px;
  /* Rounded corners */
  border: 1px solid #ccc;
  /* Border color */
  padding: 10px;
  font-size: 16px;
  width: 100%;
  /* Full width, adjust as needed */
  margin-right: 10px;
  /* Space between input and buttons */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Subtle shadow */
  transition: border-color 0.3s, box-shadow 0.3s;
}

.custom-input:focus {
  border-color: #007bff;
  /* Border color on focus */
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
  /* Shadow on focus */
  outline: none;
}

.send-button,
.upload-button {
  border-radius: 8px;
  /* Rounded corners */
  border: none;
  /* Remove default border */
  padding: 10px 20px;
  /* Padding inside the button */
  background-color: #007bff;
  /* Background color */
  color: white;
  /* Text color */
  font-size: 16px;
  /* Font size */
  cursor: pointer;
  /* Pointer cursor on hover */
  transition: background-color 0.3s, box-shadow 0.3s;
  /* Smooth transition */
  margin-left: 10px;
  /* Space between buttons */
}

.send-button:hover,
.upload-button:hover {
  background-color: #0056b3;
  /* Darker background on hover */
}

.send-button:active,
.upload-button:active {
  background-color: #004494;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.upload-button {
  background-color: #28a745;
}

.upload-button:hover {
  background-color: #218838;
}

.send-button {
  background-color: #4267b2;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 3px;
  font-size: 14px;
  cursor: pointer;
}

.send-button:hover {
  background-color: #365899;
}

.custom-button-wrapper {
  position: relative;
}

.custom-close-button {
  position: absolute;
  top: -5px;
  right: -7px;
  z-index: 2;
  background-color: white;
  border: 1px solid #ccc;
  display: none;
}

.custom-close-button:hover {
  background-color: white !important;
  color: inherit !important;
  border: 1px solid #ccc !important;
}

.floating-button:hover {
  display: block;
  background-color: #4267b2 !important;
  color: white !important;
  border: none !important;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3) !important;
}

.custom-button-wrapper:hover .custom-close-button {
  display: block;
}

.diffDate {
  margin-top: 14px;
  text-align: center;
  font-weight: 700;
  font-size: 12px;
  color: #888;
}

.custom-input {
  border-radius: 18px;
  /* Rounded corners */
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 16px;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;
}

.custom-input:focus {
  border-color: #007bff;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
  outline: none;
}

.custom-input::placeholder {
  color: #aaa;
  font-style: italic;
}

.description-vertical {
  .ant-descriptions-item-container {
    flex-direction: column;
  }
}

.upload-area {
  height: 150px;
  width: 150px;
  border-radius: 16px;
  border: 1px var(--colorNormal) dashed;
  color: var(--colorNormal);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.close-img-product-btn {
  width: 20px;
  height: 20px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.ck-content {
  max-height: 300px;
  overflow-y: scroll;
}

.product-name {
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  // width: 500px;
}

.badge-hidden {
  display: none !important;
}

.comment-box {
  display: flex;
  align-items: flex-start;
  background-color: #f1f1f1;
  border-radius: 20px;
  padding: 10px;
  width: auto;
  color: black;
}

.avatar img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  // margin-right: 10px;
}

.comment-box textarea {
  flex-grow: 1;
  border: none;
  color: rgb(0, 0, 0);
  padding: 10px;
  border-radius: 20px;
  margin-right: 10px;
  resize: none;
  margin-left: 10px;
  // height: 45px;
  max-height: 60px;
  overflow-y: auto;
  font-family: 'Lexend';
  font-size: 14px;
  // font-weight: normal;
}

.comment-box textarea::placeholder {
  color: #000000;
  font-family: 'Lexend';
  font-size: 14px;
}

.icons {
  display: flex;
  gap: 10px;
  margin-right: 10px;
}

.icons span {
  cursor: pointer;
  font-size: 20px;
}

.comment-box .button-comment {
  background-color: rgb(243, 238, 238);
  border: none;
  color: var(--colorNormal);
  font-size: 15px;
  text-align: center;
  cursor: pointer;
  padding: 10px;
  border-radius: 60%;
}

.comment-options {
  cursor: pointer;
  margin-left: auto;
}

.comment-actions {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #999;
}

.comment-actions span {
  margin-left: 8px;
  margin-bottom: 8px;
  cursor: pointer;
}

.comment-like,
.comment-reply {
  color: #007bff;
}

.emojiView {
  position: absolute;
  transform: translateY(40px);
  z-index: 9999;
}

.reaction-holer {
  display: inline-flex;
  justify-content: space-between;
  background: white;
  box-shadow: 3px 3px 30px rgba(0, 10, 10, 0.2);
  padding: 10px;
  border-radius: 60px;
  margin-top: 20px;
  width: auto;
}

.reaction-holer > img {
  margin-right: 6px;
  width: 24px;
}

.reaction-new-holer {
  display: inline-flex;
  justify-content: space-between;
  background: white;
  box-shadow: 3px 3px 30px rgba(0, 10, 10, 0.2);
  padding: 10px;
  border-radius: 60px;
  margin-top: 40px;
  width: auto;
  margin-left: 50px;
  margin-bottom: 10px;
}

.reaction-new-holer > img {
  margin-right: 6px;
  width: 40px;
}

.comment-container {
  display: flex;
  align-items: flex-start;
  // margin-bottom: 3px;
  font-family: Arial, sans-serif;
}

.comment-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: #ccc;
}

.comment-body {
  padding: 10px;
  border-radius: 18px;
  max-width: 450px;
  position: relative;
}

.comment-header {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 14px;
}

.comment-content {
  font-size: 14px;
  line-height: 1.4;
  color: #1c1e21;
}

.comment-actions {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #65676b;
  margin-bottom: 1px;
  margin-left: 15px;
}

.comment-actions span {
  margin-right: 15px;
  cursor: pointer;
}

.comment-actions span:hover {
  text-decoration: underline;
}

.comment-time {
  margin-right: 10px;
}

.comment-dot {
  position: absolute;
  top: 10px;
  right: -30px;
  cursor: pointer;
  color: #000000;
  font-size: 18px;
}

.comment-dot:hover {
  color: #000000;
}

.more-comment {
  margin-bottom: 10px;
  cursor: pointer;
  margin-left: 45px;
}

.like:hover {
  text-decoration: underline;
}

.custom-card {
  border: none !important;
  box-shadow: none !important;
}

.full-border {
  background-color: rgb(255 255 255);
  border-bottom: 1px solid #f0f0f0;
  border-left: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
}

.top-border {
  background-color: rgb(255 255 255);
  border-bottom: 1px solid #f0f0f0;
}

.custom-menu {
  width: 75%;
  margin: 0 auto;
  height: 50px;
  line-height: 50px;
  padding: 0;
  background-color: #001529;
  border-bottom: none;
  display: flex;
  align-items: center;
}

.custom-menu .ant-menu-item {
  height: 100%;
  padding: 0 20px;
  color: #bfbfbf;
  font-size: 14px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
}

.custom-menu .ant-menu-item-selected {
  background-color: #1890ff;
  color: #fff;
  border-radius: 5px;
  /* Rounded corners */
}

.custom-menu .ant-menu-item:hover {
  background-color: #40a9ff;
  color: #fff;
}

// .ant-menu-submenu-title {
//   display: flex !important;
//   justify-content: center !important;
// }

// .ant-menu-submenu-title:hover {
//   display: flex;
//   justify-content: center;
// }

.product-card {
  cursor: pointer;
  position: relative;

  .overlay-add-product {
    display: flex;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #2f2f2f55;
    z-index: 1;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 36px;
    transition: 0.2s;
  }

  &:hover .overlay-add-product {
    opacity: 1;
  }

  &.out-stock {
    opacity: 0.4;
    cursor: not-allowed;

    &:hover {
      opacity: 1;
    }
  }
}

.my-license-item {
  padding: 6px 10px;

  color: white;
  border-radius: 6px;
  box-shadow: 1px 2px 6px #e4e4e4;
  margin-bottom: 8px;

  &.lv0 {
    background-color: #c1ecfe;
  }

  &.lv1 {
    background-color: #c1fecf;
  }

  &.lv2 {
    background-color: #fff8aa;
  }

  &.lv3 {
    background-color: #ffc6bf;
  }

  &.expired {
    opacity: 0.6;
  }
}

.card-license {
  .ant-card-body {
    height: 50vh;
  }

  box-shadow: 1px 3px 8px #e4e4e4;

  &.lv1 {
    .ant-card-head {
      background-color: #c1fecf;
      border-bottom-color: #c1fecf;
    }

    border-color: #c1fecf;

    .ant-card-actions {
      border-top-color: #c1fecf;
    }
  }

  &.lv2 {
    .ant-card-head {
      background-color: #fff8aa;
      border-bottom-color: #fff8aa;
    }

    border-color: #fff8aa;

    .ant-card-actions {
      border-top-color: #fff8aa;
    }
  }

  &.lv3 {
    .ant-card-head {
      background-color: #ffc6bf;
      border-bottom-color: #ffc6bf;
    }

    border-color: #ffc6bf;

    .ant-card-actions {
      border-top-color: #ffc6bf;
    }
  }
}

.card-payment-stat {
  border-radius: 12px;
  background-color: #f9f9f9;
  padding: 8px 24px 0;

  .circle {
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
}

.modal-comment .ant-modal-content {
  padding: 15px 0px 0px 0px;

  .ant-modal-header {
    border-bottom: 1px solid #ccc;
    margin-bottom: 0px;
  }
}

.container-news {
  background-color: #f2f4f7;
}

.card-news {
  box-shadow: 0 0 20px #e2e2e2;
}

.custom-gallery img {
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.custom-body .ant-card-body {
  padding: 24px 24px 5px 24px;
}

// @media print {
//   @page {
//     size: 50mm 150mm;
//   }
// }
.print-checkout {
  div,
  p,
  h5,
  span {
    font-size: 10px;
  }

  h4 {
    font-size: 14px;
  }
}

.icon-stat {
  height: 38px;
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-size: 20px;
}

.custom-breadcrumb {
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  user-select: none;
  color: black;
}

.icon-wrapper {
  height: 42px;
  width: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--colorLight);
  border-radius: 50%;
  margin: 0 8px;
  cursor: pointer;
}

.unseen-dot {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: #0066d3;
}

.icon-wrapper-notification {
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid white !important;
  // font-size: 16px;
}

.noti-list-item {
  border-radius: 12px;
  padding: 6px !important;
  &:hover {
    background-color: var(--colorLight);
  }
  transition: 0.2s;
}
