.registerTenantWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 0 140px;
  background-image: url('../../assets/image/bg-register-tenant.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.tenantTypeItem {
  background-color: var(--colorLight);
  aspect-ratio: 1 / 1;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--colorNormal);
  &.active {
    background-color: var(--colorNormal);
    color: white;
  }
}

.serviceItem {
  background-color: var(--colorLight);
  aspect-ratio: 2 / 1;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  color: black;
  &.active {
    background-color: var(--colorNormal);
    color: white;
  }
  margin-bottom: 6px;
  padding: 4px 20px;
  overflow: hidden;
}
